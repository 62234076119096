import React, { Component } from 'react'
import { Button, Checkbox, Col, Form, Input, Row, Select, Typography } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Divider } from '@material-ui/core';
const FormItem = Form.Item;
const { Title } = Typography;
const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

class ManufacturerSubgroupDrawerComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldData : {
                name: '',
                manufacturer: '',
                door_color: []
            },
        }
    }

    initiateState = () => {
        this.setState({fieldData: {name: '', manufacturer: '', door_color: []}})
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if(!err) {
                if(this.props.manufacturerType === 'new'){
                    this.props.addManufacturer(this.state.fieldData);
                }else{
                    this.props.updateManufacturer({...this.state.fieldData, id: this.props.selectedSubgroup.id});
                }
                this.initiateState();
                this.props.onClose();
            }
        });
    }

    onChangeOption=(e)=> {
        const tmp_color = [];
        e.forEach(item=>{
            const resultColor = this.state.fieldData.door_color.find(a=>{return a['id'] == item});
            if(!resultColor && resultColor == undefined){
                const color_family = [];
                this.props.manufacturerData.find(a=>{return a.id == this.state.fieldData.manufacturer})['door_color_ids'].find(a=>{return a.id == item})['color_family_id'].split(',').forEach(element=>{
                    color_family.push(parseInt(element));
                })
                tmp_color.push({id: item, color_alias: this.props.manufacturerData.find(a=>{return a.id == this.state.fieldData.manufacturer})['door_color_ids'].find(a=>{return a.id == item})['name'], family_alias: color_family});
            } else{
                tmp_color.push({id: item, color_alias: resultColor.color_alias, family_alias: resultColor.family_alias});
            }
        });
        this.setState({fieldData: {...this.state.fieldData, door_color: tmp_color}});
    }
    selectAllDoorColors = () =>{
        const manufacturerId = this.props.selectedSubgroup?.manufacturer_id?.id || this.state.fieldData?.manufacturer
        const selectedManufacturer = this.props.manufacturerData.find(manufacturer=> manufacturer.id === manufacturerId)
        const tempColors = selectedManufacturer.door_color_ids.map(item=>{
            return {
                color_alias: item.name,
                id:item.id,
                family_alias:[Number(item.color_family_id)]
            }
        })
        this.setState({fieldData: {...this.state.fieldData, door_color: tempColors}});
    }

    unSelectAllDoorColors = () =>{
        this.setState({fieldData: {...this.state.fieldData, door_color: []}});
    }

    onChangeSelect = (e, value) => {
        let tmp_data = [...this.state.fieldData.door_color];
        tmp_data[tmp_data.findIndex(a=> {return a.id == value})].family_alias = e;
        this.setState({fieldData: {...this.state.fieldData, door_color: tmp_data}});
    }

    onChangeInput = (e, value) =>{
        let tmp_data = [...this.state.fieldData.door_color];
        tmp_data[tmp_data.findIndex(a=> {return a.id == value})].color_alias = e.target.value;
        this.setState({fieldData: {...this.state.fieldData, door_color: tmp_data}});
    }

    render() {
        const { visible, manufacturerType, drawerClasses, manufacturerData, colorFamilyData } = this.props
        const { fieldData } = this.state;
        const { getFieldDecorator } = this.props.form;
        const familyOptions = [];
        colorFamilyData && colorFamilyData.forEach(item=>{
            familyOptions.push(<Option key={item.id} value={item.id}>{item.name}</Option>)
        })
        const manufacturerItems = [];
        manufacturerData && manufacturerData.forEach(item=>{
            manufacturerItems.push(<Option key={item.id} value={item.id}>{item.name}</Option>)
        });
        const doorColorOptions = [];
        if(fieldData.manufacturer){
            manufacturerData.find(a=>{return a.id == fieldData.manufacturer})['door_color_ids']
                .forEach(item=>{
                    const findResult = fieldData.door_color.find(a=>{return a['id'] == item.id});
                    doorColorOptions.push(
                        <Row key={item.id}>
                            <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                            {
                                findResult ?
                                (
                                    <div style={{
                                        width: '100%',
                                        borderTop: '1px solid #e9e9e9',
                                        marginTop: '5px',
                                        padding: '10px 16px'
                                    }}>
                                        <Row gutter={8}>
                                            <Col span={24}>
                                                <FormItem
                                                    key={"colorAlias " + item.id}
                                                    {...formItemLayout}
                                                    label="Color Alias"
                                                >
                                                    {getFieldDecorator("color_alias " + item.id, {
                                                        initialValue    : findResult.color_alias,
                                                        rules: [{ required: true, message: 'Please input Color Alias!' }],
                                                    })(
                                                        (<Input
                                                            placeholder="Please input Color Alias"
                                                            onChange={(e)=>this.onChangeInput(e, findResult.id)}
                                                        />)
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row gutter={8}>
                                            <Col span={24}>
                                                <FormItem
                                                    key={"colorFamilyId " + item.id}
                                                    {...formItemLayout}
                                                    label="Family Alias"
                                                >
                                                    {getFieldDecorator("color_family_id " + item.id, {
                                                        initialValue    : findResult.family_alias,
                                                        rules: [{ required: true, message: 'Please select Door Color Family!' }],
                                                    })(
                                                        (<Select
                                                                mode="multiple"
                                                                style={{width : '100%'}}
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select Door Color Family"
                                                                onChange={(e)=>this.onChangeSelect(e, findResult.id)}
                                                            >
                                                            {familyOptions}
                                                        </Select>)
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }
                        </Row>);
                })
        }
        const defaultCheckValue = [];
        fieldData.door_color.forEach(item=>{
            defaultCheckValue.push(item.id);
        })
        return (
            <Drawer
                style={{zIndex:700}}
                anchor="right"
                open={visible}
                onClose={() => {this.props.onClose()}}
                variant = "temporary"
                className={drawerClasses}
                onRendered={() => {
                    const {selectedSubgroup, manufacturerType} = this.props;
                    if(manufacturerType !== "new"){
                        this.setState({fieldData: {
                            name: selectedSubgroup.name,
                            manufacturer: selectedSubgroup.manufacturer_id.id,
                            door_color: selectedSubgroup.door_color_alias_ids.map(item=>({id: item.door_color_id, family_alias: item.color_family_alias, color_alias:item.alias_name}))
                        }})
                    }
                }}
            >
                <div
                    tabIndex={0}
                    role="button"
                >
                    <Title level={3} className="pt-16" style={{ textAlign: "center" }}>
                        {manufacturerType === "new" ? "Add" : "Edit"} Manufacturer Subgroup
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{marginTop: "20px"}}>
                        <Row gutter={8}>
                            <Col span={2}/>
                            <Col span={22}>
                                <FormItem
                                    key='name'
                                    {...formItemLayout}
                                    label="Name"
                                >
                                    {getFieldDecorator("subgroup_name", {
                                        initialValue    : fieldData.name,
                                        rules: [{ required: true, message: 'Please input Subgroup Name!' }],
                                    })(
                                        (<Input
                                            placeholder="Please input Subgroup Name"
                                            onChange={(e)=>{this.setState({fieldData: {...fieldData, name: e.target.value}})}}
                                        />)
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={2} />
                            <Col span={22}>
                                <FormItem 
                                    key="manufacturer_id"
                                    {...formItemLayout}
                                    label="Manufacturer"
                                >
                                    {getFieldDecorator('manufacturer', {
                                        initialValue: fieldData.manufacturer,
                                        rules: [{ required: true, message: 'Please select your Manufacturer!' }],
                                    })(
                                        <Select
                                            dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }} placeholder="Please select your Manufacturer"
                                            onChange={ (value) => {this.setState({fieldData: {...fieldData, manufacturer: value}})} }
                                        >
                                            {manufacturerItems}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={2} />
                            <Col span={22}>
                                <FormItem 
                                    key="door_color_title"
                                    {...formItemLayout}
                                    label="Door Style"
                                >
                                    <Button disabled={!fieldData?.manufacturer} style={{ width:"100%" }} onClick={this.selectAllDoorColors}>
                                        Select All
                                    </Button>
                                    <Button disabled={!fieldData?.door_color?.length} style={{ width:"100%", marginTop: 10 }} onClick={this.unSelectAllDoorColors}>
                                        Unselect All
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={2} />
                            <Col span={22}>
                                <Checkbox.Group style={{width: '100%'}} value={[...defaultCheckValue]} onChange={this.onChangeOption}>{doorColorOptions}</Checkbox.Group>
                            </Col>
                        </Row>
                        <div
                            style={{
                                left: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button style={{ marginRight: 8 }} onClick={() => {this.props.onClose(); this.initiateState();}}>
                                Cancel
                            </Button>
                            <Button
                                onClick={this.handleSubmit} 
                                variant="contained" 
                                color="primary" 
                            >
                                {manufacturerType === "new" ? "ADD" : "UPDATE"}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Drawer>
        )
    }
}

const ManufacturerSubgroupDrawer = Form.create()(ManufacturerSubgroupDrawerComponent);
export default (withStyles({withTheme: true})(ManufacturerSubgroupDrawer));